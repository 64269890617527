import Axios from "axios";
let token = window.localStorage.getItem("accessToken") || "";

const axios = Axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? `http://192.168.0.242:7011/api/v1`
      : `https://api.tahsinasworld.com/api/v1`, // testing

  // baseURL: `https://api.sellmart.com.bd/api/v1`,

  headers: {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  },
});

export default axios;
